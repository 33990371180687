import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';
import styled from 'styled-components';
import Button from '../button';
import icon from './info.svg';
import transitionimage from '../../assets/images/transition.jpg';

const CovidStyles = styled.div`
  margin: 50px 0;
  grid-column: 3 / span 20;
  margin-bottom: 20px;
  border: 1px solid var(--grey);
  background: var(--rust);
  color: var(--beige);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 400px;
    @media (min-width: 768px) {
      flex-direction: row;
      text-align: left;
    }
    h2 {
      font-weight: 100;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 20px 0 20px 0px;
      @media (min-width: 768px) {
        margin: 0px 0px 0px 15px;
      }
    }
    .icon {
      width: 50px;
    }
  }
  .right {
    max-width: 400px;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
    .title {
    }
    .text {
    }
  }
`;
const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Covid() {
  return (
    <div className="SiteContainer">
      <CovidStyles className="experience">
        <div className="left">
          <img className="icon" src={icon} alt="" />
          <h2>Important Information Regarding Your Safety</h2>
        </div>
        <div className="right">
          {' '}
          <AniLink
            className="link"
            to="/faq"
            cover
            direction="down"
            activeClassName="active"
            bg={transitionImageDiv}
          >
            Click to read more about our health and safety protocols.
          </AniLink>
        </div>
      </CovidStyles>
    </div>
  );
}
