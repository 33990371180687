import React from 'react';
import styled from 'styled-components';
import hill1 from './motifs/hill_1.png';

const Motif1styles = styled.div`
  position: absolute;
  left: 2%;
  top: 50%;
  z-index: 0;
  max-width: 5vw;
  opacity: 0.2;
`;

export default function Motif1() {
  return (
    <>
      <Motif1styles>
        <img src={hill1} alt="" />
      </Motif1styles>
    </>
  );
}
