import React from 'react';
import styled from 'styled-components';
import tree5 from './motifs/tree_5.png';

const Motif1styles = styled.div`
  position: absolute;
  left: 5%;
  bottom: 3%;
  z-index: 0;
  width: 8vw;
  max-width: 100px;
  opacity: 0.2;
`;

export default function Motif1() {
  return (
    <>
      <Motif1styles>
        <img src={tree5} alt="" />
      </Motif1styles>
    </>
  );
}
