import React, { useState, useEffect } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';

import Footer from './Footer';
import Menu from './Menu';
import FullMenu from './fullmenu/FullMenu';
import 'normalize.css';

import Typography from '../styles/Typography';

export default function Layout({ children }) {
  // Cursor
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);
  const springConfig = { damping: 70, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);
  const springConfig2 = { damping: 150, stiffness: 700 };
  const cursorXSpring2 = useSpring(cursorX, springConfig2);
  const cursorYSpring2 = useSpring(cursorY, springConfig2);

  useEffect(() => {
    // cursor
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 16);
      cursorY.set(e.clientY - 16);
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  // Menu
  const [open, setOpen] = useState(false);

  return (
    <>
      <Typography />

      <main className="SiteMain">
        <div>
          <motion.div
            className="cursor"
            style={{
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
          />
          <motion.div
            className="cursor2"
            style={{
              translateX: cursorXSpring2,
              translateY: cursorYSpring2,
            }}
          />

          <FullMenu open={open} setOpen={setOpen} />

          <Menu open={open} setOpen={setOpen} />
          {children}
        </div>
      </main>
    </>
  );
}
