import React from 'react';
import Layout from './src/components/Layout';
import Footer from './src/components/Footer';
import SEO from './src/components/SEO';
import GlobalStyles from './src/styles/GlobalStyles';
// import Typography from './src/styles/Typography';

export function wrapPageElement({ element, props }) {
  return (
    <>
      <SEO />
      <GlobalStyles />

      <Layout {...props}>
        {element}
        <Footer />
      </Layout>
    </>
  );
}
