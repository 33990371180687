import React from 'react';
import styled from 'styled-components';
import travelline from '../../assets/images/travel_line_4.svg';
// home images
const Line = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vw;
  z-index: 0;
  opacity: 0.4;
`;

export default function Travelline4() {
  return (
    <Line>
      <img src={travelline} alt="" />
    </Line>
  );
}
