import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func } from 'prop-types';
import {
  motion,
  staggerChildren,
  delayChildren,
  transition,
} from 'framer-motion';
import {
  faFacebook,
  faInstagram,
  faVimeo,
  faYoutube,
  faTwitter,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import footerlogo from '../../assets/images/nomads_horizontal.svg';
import mffooterlogo from '../../assets/images/mf_logo_footer_beige.svg';

const FooterStyles = styled.div`
  position: relative;
  width: 100%;
  .footer-line {
    // border-top: 1px solid var(--olive);
  }
  .footerContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16.6666666667vw, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 0;
    position: relative;
    grid-template-areas: 'left center right';
    box-sizing: border-box;
    padding: 0 20px;
    @media (min-width: 768px) {
      padding: 0 0;
    }
    .left {
      display: flex;

      align-items: center;
      justify-content: space-between;
      grid-area: left;

      grid-column: 1 / 7;
      margin-left: 0px;
      @media (min-width: 700px) {
        justify-content: flex-start;
        grid-column: 2 / 5;
        //margin-left: 30px;
      }
      @media (min-width: 1200px) {
        justify-content: center;
        grid-column: 2 / 4;
        //margin-left: 30px;
      }

      .socials {
        margin-left: 30px;
        ul {
          margin: 0;
          padding: 0;
          li {
            margin-right: 15px;
            padding: 0;
            list-style: none;
            display: inline-block;
            font-size: 1.2em;
            @media (min-width: 1170px) {
              font-size: 1.5em;
            }
            a {
              color: var(--beige);
            }
          }
        }
      }
      .menulogo {
        .footer-logo {
          width: 120px;
          @media (min-width: 1170px) {
            width: 200px;
          }
        }
      }
      .dates {
        margin-left: 30px;
      }
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-area: center;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-area: right;

      .menulogo {
        .footer-logo {
          width: 150px;
        }
      }
    }
  }
  .footer-innner {
  }
`;

const SocialsContainer = {
  open: {
    transition: {
      delayChildren: 0.75,
      staggerChildren: 0.075,
    },
  },
  closed: {
    opacity: 0,
  },
};

const SocialsItem = {
  open: { opacity: 1, y: '0' },
  closed: { opacity: 0, y: '-2px' },
};

export default function Footer({ open }) {
  return (
    <FooterStyles>
      <div className="footerContainer">
        <div className="left">
          <div className="menulogo">
            <img className="footer-logo" src={mffooterlogo} alt="" />
          </div>
          <div className="socials">
            <motion.ul
              variants={SocialsContainer}
              animate={open ? 'open' : 'closed'}
            >
              <motion.li variants={SocialsItem}>
                <a
                  href="https://www.facebook.com/magneticfieldsfestival/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </motion.li>
              <motion.li variants={SocialsItem}>
                <a
                  href="https://www.instagram.com/magneticfieldsfestival/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </motion.li>
              <motion.li variants={SocialsItem}>
                <a
                  href="https://www.youtube.com/channel/UC-fZp9wjxFL00hhtUDn3e7Q"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </motion.li>
              <motion.li variants={SocialsItem}>
                <a href="https://twitter.com/magneticfields" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </motion.li>
              <motion.li variants={SocialsItem}>
                <a
                  href="https://soundcloud.com/magneticfieldsfestival"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faSoundcloud} />
                </a>
              </motion.li>
            </motion.ul>
          </div>
        </div>
        <div className="center" />
        <div className="right" />
      </div>
    </FooterStyles>
  );
}
