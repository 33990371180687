import React from 'react';
import styled from 'styled-components';
import tiger1 from './motifs/tiger_2.png';

const Motif1styles = styled.div`
  position: absolute;
  left: 25%;
  top: 25%;
  z-index: 0;
  width: 8vw;
  max-width: 120px;
  opacity: 0.2;
`;

export default function Motif1() {
  return (
    <>
      <Motif1styles>
        <img src={tiger1} alt="" />
      </Motif1styles>
    </>
  );
}
