import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import transitionimage from '../assets/images/transition.jpg';

const ButtonStyles = styled.div`
  a {
    padding: 10px 20px;
    background: var(--earth);
    color: var(--beige);
    border: 1px solid var(--grey);
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    letter-spacing: 1px;
    transition: all 0.1s ease-in;
    display: inline-block;
    margin-bottom: 30px;
    cursor: pointer;
    .link {
      text-decoration: none;
    }
    &:hover {
      background: var(--grey);
    }
  }
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Button({ linkto, linktext }) {
  return (
    <ButtonStyles>
      <AniLink
        className="link"
        to={linkto}
        cover
        direction="down"
        activeClassName="active"
        bg={transitionImageDiv}
      >
        {linktext}
      </AniLink>
    </ButtonStyles>
  );
}
