import React from 'react';
import styled from 'styled-components';
// logos
import rayban from '../../assets/images/brand-logos/ray-ban.png';
import jameson from '../../assets/images/brand-logos/jameson.png';
import absolut from '../../assets/images/brand-logos/absolut.png';
import bira from '../../assets/images/brand-logos/bira.png';
import transportal from '../../assets/images/brand-logos/transportal.png';
import skrap from '../../assets/images/brand-logos/skrap.png';
import morningfresh from '../../assets/images/brand-logos/morning-fresh.png';
import tres from '../../assets/images/brand-logos/tres.png';
import figmaple from '../../assets/images/brand-logos/figmaple.png';
import pco from '../../assets/images/brand-logos/pco.png';
import tatva from '../../assets/images/brand-logos/tatva.png';
import monkey47 from '../../assets/images/brand-logos/monkey47.png';
import svami from '../../assets/images/brand-logos/svami.png';
import bhu from '../../assets/images/brand-logos/bhu.png';
import anonymous from '../../assets/images/brand-logos/anonymous.png';
import hautcare from '../../assets/images/brand-logos/hautcare.png';
import sanspublic from '../../assets/images/brand-logos/sanspublic.png';
import redbull from '../../assets/images/brand-logos/redbull.png';
import atomx from '../../assets/images/brand-logos/atomx.png';
import vitality from '../../assets/images/brand-logos/vitality.png';
import bisleri from '../../assets/images/brand-logos/bisleri.png';

const LogoStyles = styled.div`
  margin: 50px 0;
  grid-column: 1 / span 24;
  margin-bottom: 20px;
  color: var(--gray);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 100px;
  @media (min-width: 768px) {
    grid-column: 1 / span 24;
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1200px) {
    grid-column: 4 / span 18;
    grid-template-columns: repeat(5, 1fr);
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
  }
`;

export default function Motif1() {
  return (
    <>
      <div className="SiteContainer">
        <LogoStyles>
          <div className="logo">
            <img src={rayban} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={jameson} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={bira} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={monkey47} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={tres} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={figmaple} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={pco} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={bhu} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={transportal} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={redbull} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={sanspublic} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={vitality} alt="" />
          </div>

          <div className="logo">
            {' '}
            <img src={skrap} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={anonymous} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={morningfresh} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={svami} alt="" />
          </div>

          <div className="logo">
            {' '}
            <img src={tatva} alt="" />
          </div>
          <div className="logo">
            {' '}
            <img src={hautcare} alt="" />
          </div>

          <div className="logo">
            {' '}
            <img src={atomx} alt="" />
          </div>

          <div className="logo">
            {' '}
            <img src={bisleri} alt="" />
          </div>
        </LogoStyles>
      </div>
    </>
  );
}
