import { createGlobalStyle, css } from 'styled-components';
import noise from '../assets/images/noise3.png';
import grid from '../assets/images/grid.svg';

const GlobalStyles = createGlobalStyle`${css`
  :root {
    --red: #ff4949;
    --beige: #fffef2;
    --rust: #6d413c;
    --olive: #5a6345;
    --earth: #636357;
    --grey: #333333;
    --grey-light: #434343;
    --yellow: #cab982;
  }

  .olive {
    color: var(--olive);
  }
  .rust {
    color: var(--rust);
  }
  .cursor {
    position: fixed;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid var(--earth);
    z-index: 200;
    pointer-events: none;
  }
  .cursor2 {
    position: fixed;
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    //background: rgba(51, 51, 51, 0.1);
    opacity: 1;
    border: 1px solid var(--earth);
    z-index: 200;
    pointer-events: none;
  }

  html {
    background-color: var(--beige);
    background-image: url(${noise});
    background-size: 100px;
    font-size: 16px;
  }

  body {
    font-size: 1em;
    min-height: 100vh;
    position: relative;
    background-image: url(${grid});
    background-size: 16.6666666667vw;
    color: var(--grey);
    cursor: 'pointer';
  }

  .gatsby-image-wrapper img[src*='base64\\,'] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
  }

  html {
    scrollbar-width: thin;
  }
  .beige {
    color: var(--beige);
  }
  .rust {
    color: var(--rust);
  }
  .earth {
    color: var(--rust);
  }
  .olive {
    color: var(--olive);
  }
  .grey {
    color: var(--grey);
  }

  img {
    max-width: 100%;
  }

  p {
    font-size: 1rem;
    line-height: 1.2;
  }

  /* container grid */
  .SiteMain {
    margin-top: 50px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
  .SiteContainer {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-gap: 0;
    position: relative;
    //padding: 4.17vw 4.17vw;
    padding: 0 7.5vw;
  }

  .full-height {
    min-height: 100vh;
  }
`}`;

export default GlobalStyles;
