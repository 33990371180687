import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';
import styled from 'styled-components';
import Mailchimp from 'react-mailchimp-form';
import Button from '../button';
import icon from './info.svg';
import transitionimage from '../../assets/images/transition.jpg';

const Newsletter = styled.div`
  margin: 50px 0;
  grid-column: 3 / span 20;
  margin-bottom: 20px;
  border: 1px solid var(--grey);
  background: var(--olive);
  color: var(--beige);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 400px;
    @media (min-width: 768px) {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
    }
    h2 {
      font-weight: 100;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 10px 0 10px 0px;
      @media (min-width: 768px) {
        margin: 0px 0px 0px 0px;
      }
    }
    .icon {
      width: 50px;
    }
  }
  .right {
    max-width: 400px;
    text-align: center;
    button,
    input[type='submit'],
    input[type='reset'] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
    input {
      padding: 10px 20px;
      border: 1px solid var(--grey);
      width: 100%;
      width: 100%;
      /* width: 200px; */
      box-sizing: border-box;
      text-align: center;
      max-width: 400px;
      @media (min-width: 768px) {
        text-align: center;
      }
    }
    button {
      padding: 10px 20px;
      background: var(--beige);
      color: var(--earth);
      border: 1px solid var(--grey);
      width: 100%;
      max-width: 400px;
    }
    @media (min-width: 768px) {
      text-align: left;
    }
    .title {
    }
    .text {
    }

    .msg-alert {
      p {
        color: var(--beige) !important;
      }
    }
  }
`;
const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Covid() {
  return (
    <div className="SiteContainer">
      <Newsletter className="experience">
        <div className="left">
          <h2>What's Going on ?!</h2>
          <p> Join our Newsletter to stay updated on major announcements.</p>
        </div>
        <div className="right">
          {' '}
          <Mailchimp
            action="https://magneticfields.us3.list-manage.com/subscribe/post-json?u=6a5696a71fb929a68c951a4a9&id=1fa3f1219f"
            fields={[
              {
                name: 'EMAIL',
                placeholder: 'FIELDER@YOUREMAIL.COM',
                type: 'email',
                required: true,
              },
            ]}
            messages={{
              sending: 'Sending...',
              success: 'Thank you for subscribing!',
              error: 'An unexpected internal error has occurred.',
              empty: 'Please enter an e-mail address.',
              duplicate: 'E-mail already subscribed.',
              button: 'SUBSCRIBE',
            }}
            className="mailchimpform"
          />
        </div>
      </Newsletter>
    </div>
  );
}
