import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faVimeo,
  faYoutube,
  faTwitter,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import Newsletter from './newsletter/newsletter';
import Footerlogos from './footerlogos/Footerlogos';
import Covid from './covid/covid';
import footerlogo from '../assets/images/nomads_horizontal.svg';
import mffooterlogo from '../assets/images/mf_logo_footer.svg';
import Travelline4 from './travellines/Travelline4';
import Travelline4bg from '../assets/images/travel_line_4.svg';

const FooterStyles = styled.div`
  position: relative;
  padding: 2.5vw 2.5vw;
  @media (min-width: 768px) {
    padding: 7.5vw 7.5vw;
  }
  background-image: url(${Travelline4bg});
  background-position: top;
  background-size: 100%;
  .footer-line {
    // border-top: 1px solid var(--olive);
  }
  .footerContainer {
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    //grid-template-columns: 2fr 1fr 2fr;

    @media (min-width: 768px) {
      grid-template-columns: 2fr 2fr;
    }

    grid-gap: 0;
    position: relative;
    grid-template-areas: 'left  right';
    box-sizing: border-box;
    margin-top: 7.5vw;
    height: 100px;

    .left {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      grid-area: left;
      flex-direction: row;
      @media (min-width: 768px) {
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
      }

      .logo {
        .footer-logo {
          width: 150px;
          @media (min-width: 768px) {
            width: 200px;
          }
        }
      }
      .dates {
        margin-left: 10px;
        margin-top: 0px;
        text-align: right;
        font-size: 0.9em;
        @media (min-width: 768px) {
          font-size: 1em;
          text-align: left;
          margin-left: 30px;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-area: center;
    }
    .right {
      display: flex;
      flex: 1;
      align-items: center;

      justify-content: space-between;
      grid-area: right;
      flex-direction: row;

      @media (min-width: 768px) {
        justify-content: flex-end;
        flex-direction: row;
        margin-right: 0;
        align-items: center;
      }
      .logo {
        .footer-logo {
          width: 120px;
          @media (min-width: 768px) {
            width: 120px;
          }
        }
      }
      .socials {
        margin-left: 0;
        margin-top: 20px;
        margin-bottom: 30px;
        @media (min-width: 768px) {
          margin-left: 20px;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            margin-right: 15px;
            padding: 0;
            list-style: none;
            display: inline-block;
            font-size: 1.2em;
            @media (min-width: 768px) {
              font-size: 1.5em;
            }
            a {
              color: var(--rust);
            }
          }
        }
      }
    }
  }
  .footer-innner {
  }
`;
export default function Footer() {
  return (
    <FooterStyles>
      <Covid />
      <Newsletter />
      <Footerlogos />
      <div className="footer-line" />
      <div className="footerContainer">
        <div className="left">
          <div className="logo">
            <img className="footer-logo" src={footerlogo} alt="" />
          </div>
          <div className="dates">
            <div className="date b">MARCH 19-21 2021 </div>
            <div className="venue ">Nahargarh, Ranthambhore</div>
          </div>
        </div>

        <div className="right">
          <div className="logo">
            <img className="footer-logo" src={mffooterlogo} alt="" />
          </div>
          <div className="socials">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/magneticfieldsfestival/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/magneticfieldsfestival/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC-fZp9wjxFL00hhtUDn3e7Q"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/magneticfields" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li>
                <a
                  href="https://soundcloud.com/magneticfieldsfestival"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faSoundcloud} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </FooterStyles>
  );
}
