import React from 'react';
import styled from 'styled-components';
import river2 from './motifs/river_4.png';

const Motif1styles = styled.div`
  position: absolute;
  left: 7%;
  bottom: 0%;
  z-index: 0;
  width: 8vw;
  max-width: 100px;
  opacity: 0.2;
`;

export default function Motif1() {
  return (
    <>
      <Motif1styles>
        <img src={river2} alt="" />
      </Motif1styles>
    </>
  );
}
