import React, { useState, useEffect } from 'react';
import { bool, func } from 'prop-types';
// import { gsap } from 'gsap';
import { motion } from 'framer-motion';
import TransitionLink from 'gatsby-plugin-transition-link';
import styled from 'styled-components';
import { Link } from 'gatsby';
import noise from '../../assets/images/noise3.png';
import grid from '../../assets/images/grid.svg';
import nomadsitalic from '../../assets/images/nomads_italic.svg';
import symbol from '../../assets/images/symbol.svg';
import logodates from '../../assets/images/logo_dates.svg';
import travelline from '../../assets/images/travel_line_1.svg';
import menustar from '../../assets/images/menu_star.svg';

import bgtile1 from '../../assets/images/menu-bg/tile1.jpg';
import bgtile2 from '../../assets/images/menu-bg/tile2.jpg';
import bgtile3 from '../../assets/images/menu-bg/tile3.jpg';
import bgtile4 from '../../assets/images/menu-bg/tile4.jpg';
import bgtile5 from '../../assets/images/menu-bg/tile5.jpg';
import Footer from './menufooter';

// motifs
import Mountain1 from '../motifs/motif3';
import Tiger1 from '../motifs/motif4';
import Tree1 from '../motifs/motif5';
import Fort1 from '../motifs/motif6';
import Tree2 from '../motifs/motif7';
import Tree3 from '../motifs/motif8';
import River2 from '../motifs/motif9';
import Tree4 from '../motifs/motif10';
import Mountain2 from '../motifs/motif11';
import Hill1 from '../motifs/motif1';

const MenuOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  position: fixed;
  overflow: hidden;
  transform: translate(0, -100%);
  transition: transform 1s cubic-bezier(0.26, 1.04, 0.54, 1);
  //transition: transform 1s cubic-bezier(0.32, 0.24, 0.15, 1);
  background-image: url(${noise});
  background-color: var(--beige);
  background-size: 100px;
  &.open {
    transform: translate(0, 0);
  }
  .close-button {
    position: absolute;
    color: var(--gray);
    height: 60px;
    width: 60px;
    @media (min-width: 1170px) {
      height: 80px;
      width: 80px;
    }
    right: 20px;
    top: 20px;
    @media (min-width: 768px) {
      right: 80px;
      top: 80px;
    }
    border-radius: 100%;
    z-index: 10;
    //cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--earth);
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.26, 1.04, 0.54, 1);

    &:before {
      content: '';
      position: absolute;
      border: 1px solid var(--earth);
      height: 100px;
      width: 100px;
      border-radius: 100%;
      left: 50%;
      top: 50%;
      bottom: 0;
      right: 0;
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    &:hover {
      opacity: 0.8;
      background: var(--earth);
      color: var(--beige);
      &:before {
        opacity: 1;
      }
    }
  }
  .travel-line {
    position: absolute;
    width: 100vw;
    z-index: 3;
    mix-blend-mode: normal;
    opacity: 0.4;
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: var(--rust);
    height: 100px;
    z-index: 5;
    display: flex;
    align-items: center;
  }
  .bg-grid-wrap {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-image: url(${grid});
    background-size: 16.6666666667vw;
  }
  .bg-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16.6666666667vw, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 0;
    .bg-tile {
      color: rgba(0, 0, 0, 0);
      &#b1 {
        background-image: url(${bgtile1});
        background-size: cover;
      }
      &#b4 {
        background-image: url(${bgtile2});
        background-size: cover;
      }
      &#b9 {
        background-image: url(${bgtile5});
        background-size: cover;
      }
      &#b18 {
        background-image: url(${bgtile4});
        background-size: cover;
      }
      &#b13 {
        background-image: url(${bgtile3});
        background-size: cover;
      }
    }
  }

  .bg-grid::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  .bg-grid > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  /* Just to make the grid visible */

  .bg-grid > * {
    background: rgba(0, 0, 0, 0);
  }

  /* style the menu and the logo */

  .menu-grid-wrap {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 3;
  }
  .menu-detail {
    font-size: 14px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    left: 20;
    width: 100px;
    z-index: 10;
    .content {
      transform: rotate(-90deg);
      align-items: center;
      white-space: nowrap;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
    }
  }
  .menu-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16.6666666667vw, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 0;
  }

  .menu-grid::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  .menu-grid > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  .menu {
    height: calc(100vh - 180px);
    min-height: 480px;
    grid-column: 2 / span 4;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media (min-width: 768px) {
      height: 85vh;
      grid-column: 2 / 4;
      align-items: center;
    }

    nav {
      cursor: pointer;
      ul {
        cursor: pointer;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          &.hidelarge {
            display: block;
            margin-bottom: 15px;
            @media (min-width: 768px) {
              display: none;
            }
          }
          cursor: pointer;
          text-align: center;
          @media (min-width: 768px) {
            text-align: left;
          }
          list-style: none;
          margin: 0;
          padding-bottom: 10px;
          display: block;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          transform: translateX(0px);
          &:hover {
            transform: translateX(5px);
          }
        }
        a {
          cursor: pointer;
          font-size: 2.3rem;
          @media (min-width: 768px) {
            font-size: 3rem;
          }

          @media (min-width: 1100px) {
            font-size: 3.4rem;
          }
          @media (min-width: 1200px) {
            font-size: 4.8rem;
          }
          font-family: 'magnetic fields';
          text-transform: uppercase;
          text-decoration: none;
          //border-bottom: 4px solid var(--grey);
          position: relative;
          color: var(--grey-light);
          &.active {
            color: var(--rust);
            text-decoration: underline;
          }
          &::after {
            content: '';
            opacity: 0;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            cursor: pointer;
            // transform: skewX(-3deg);
            color: var(--grey);
            &::after {
              opacity: 1;
              content: '';
              position: absolute;
              height: 25px;
              width: 25px;
              background-image: url(${menustar});
              background-size: 100%;
              left: -30px;
              top: 20%;
              transform: translateY(-50%);
            }
            &.active {
              cursor: pointer;
              //background: var(--earth);
              color: var(--rust);
              transform: skewX(-3deg);
            }
          }
        }
      }
    }
  }
  .logo {
    height: 50vh;
    grid-column: 4 / 6;
    &.hidesmall {
      display: none;
      @media (min-width: 768px) {
        display: flex;
      }
    }
    @media (min-width: 768px) {
      height: 85vh;
      grid-column: 4 / 6;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .symbol {
      width: 100%;
      max-width: 150px;
      display: block;
      margin-bottom: 20px;
    }
    .magnetic-fields {
      max-width: 300px;
      text-align: center;
      margin-bottom: 15px;
      text-transform: uppercase;
      p {
        font-size: 1.4rem;
        margin: 0;
        padding: 0;
      }
    }
    .title {
      width: 100%;
      max-width: 300px;
      display: block;
    }
    .text {
      font-size: 1rem;
      max-width: 300px;
      text-align: center;
      margin-bottom: 30px;
    }
  }
`;

const Menucontainer = {
  open: {
    opacity: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.05,
    },
  },
  closed: {
    opacity: 1,
  },
};

const item = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '-10px' },
};

const BGcontainer = {
  open: {
    opacity: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.075,
    },
  },
  closed: {
    opacity: 0,
  },
};

const BGitem = {
  open: { opacity: 1, x: 0, scale: 1 },
  closed: { opacity: 0, x: 0, scale: 1 },
};

export default function FullMenu({ open, setOpen }) {
  const className = open ? 'open' : 'closed';

  /* function handleClick() {
    setOpen('');
  }
  */

  const handleCloseModal = () => {
    // event.preventDefault();
    setOpen('');
  };

  useEffect(() => {});

  const handleMenuClick = (event) => {
    // event.preventDefault();
    // Using the parent component's state to keep track of the menu
    // alert('232');
    setOpen(!open);
  };

  // const handleCloseModal = () => setOpen(!open);

  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      // handleCloseModal();
      handleMenuClick();
    }
  };

  // Wrap the require in check for window
  if (typeof window !== `undefined`) {
    // const module = require("module")
    window.addEventListener('keydown', (event) => {
      handleEsc(event);
    });
  }
  // const bg = 'bg'+.{ transitionimage };
  // const transition = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;
  // const transition2 = `url('${transitionimage2}') center / cover  no-repeat fixed padding-box content-box white`;
  /*
import Mountain1 from '../motifs/motif3';
import Tiger1 from '../motifs/motif4';
import Tree1 from '../motifs/motif5';
import Fort1 from '../motifs/motif6';
import Tree2 from '../motifs/motif7';
import Tree3 from '../motifs/motif8';
import River2 from '../motifs/motif9';
import Tree4 from '../motifs/motif10';
import Mountain2 from '../motifs/motif11';
*/
  return (
    <MenuOverlay className={className} open={open}>
      <Mountain1 />
      <Tiger1 />
      <Fort1 />
      <Hill1 />
      <Tree1 />
      <River2 />
      <Mountain2 />
      <div className="close-button" onClick={(event) => handleMenuClick(event)}>
        <span className="b">Close</span>
      </div>
      <div className="footer">
        <Footer open={open} />
      </div>
      <div className="travel-line">
        <img src={travelline} alt="" />
      </div>
      <div className="menu-grid-wrap">
        <div className="menu-grid">
          <div />
          <div className="menu">
            <nav>
              <motion.ul
                variants={Menucontainer}
                animate={open ? 'open' : 'closed'}
              >
                {' '}
                <motion.li variants={item} className="hidelarge">
                  <img src={logodates} alt="" />
                </motion.li>
                <motion.li variants={item}>
                  <Link to="/" onClick={() => setOpen(!open)}>
                    Home
                  </Link>
                </motion.li>
                <motion.li variants={item}>
                  <Link to="/ranthambore" onClick={() => setOpen(!open)}>
                    Ranthambore
                  </Link>
                </motion.li>
                <motion.li variants={item}>
                  <Link to="/experiences" onClick={() => setOpen(!open)}>
                    Experiences
                  </Link>
                </motion.li>
                <motion.li variants={item}>
                  <Link to="/nahargarh" onClick={() => setOpen(!open)}>
                    Nahargarh
                  </Link>
                </motion.li>
                <motion.li variants={item}>
                  <Link to="/information" onClick={() => setOpen(!open)}>
                    Information
                  </Link>
                </motion.li>
                <motion.li variants={item}>
                  <Link to="/faq" onClick={() => setOpen(!open)}>
                    Faq & Safety
                  </Link>
                </motion.li>
                <motion.li variants={item}>
                  <a target="_blank" href="http://tickets.magneticfields.in">
                    Book Tickets
                  </a>{' '}
                </motion.li>
              </motion.ul>
            </nav>
          </div>
          <div className="logo hidesmall">
            <div className="symbol">
              <img src={symbol} alt="" />
            </div>
            <div className="magnetic-fields">
              <p className="b">Magnetic Fields</p>
            </div>
            <div className="title">
              <img src={nomadsitalic} alt="" />
            </div>
            <div className="text">
              <p className="b-i">March 19-21 2021</p>
              <p className="i">Limited Passes Available on Feb 10.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-detail">
        <div className="content">
          <span className="b">28.12421, 75.398311 </span>
          <br />
          <span className="i"> Khilchipur, Rajasthan </span>
        </div>
      </div>
      <div className="bg-grid-wrap">
        <motion.div
          className="bg-grid"
          variants={BGcontainer}
          animate={open ? 'open' : 'closed'}
        >
          <motion.div id="b1" className="bg-tile" variants={BGitem}>
            .
          </motion.div>
          <div id="b2" className="bg-tile">
            .
          </div>
          <div id="b3" className="bg-tile">
            .
          </div>
          <motion.div id="b4" className="bg-tile" variants={BGitem}>
            .
          </motion.div>
          <div id="b5" className="bg-tile">
            .
          </div>
          <div id="b6" className="bg-tile">
            .
          </div>
          <div id="b7" className="bg-tile">
            .
          </div>
          <div id="b8" className="bg-tile">
            .
          </div>
          <motion.div id="b9" className="bg-tile" variants={BGitem}>
            .
          </motion.div>
          <div id="b10" className="bg-tile">
            .
          </div>
          <div id="b11" className="bg-tile">
            .
          </div>
          <div id="b12" className="bg-tile">
            .
          </div>
          <motion.div id="b13" className="bg-tile" variants={BGitem}>
            .
          </motion.div>
          <div id="b14" className="bg-tile">
            .
          </div>
          <div id="b15" className="bg-tile">
            .
          </div>
          <div id="b16" className="bg-tile">
            .
          </div>
          <div id="b17" className="bg-tile">
            .
          </div>
          <motion.div id="b18" className="bg-tile" variants={BGitem}>
            .
          </motion.div>
          <div id="b19" className="bg-tile">
            .
          </div>
          <div id="b20" className="bg-tile">
            .
          </div>
          <div id="b21" className="bg-tile">
            .
          </div>
          <div id="b22" className="bg-tile">
            .
          </div>
          <div id="b23" className="bg-tile">
            .
          </div>
          <div id="b24" className="bg-tile">
            .
          </div>
        </motion.div>
      </div>
    </MenuOverlay>
  );
}
