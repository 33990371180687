import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import star from '../assets/images/star.svg';
import logo from '../assets/images/nomads_vertical.svg';
import transitionimage from '../assets/images/transition.jpg';

const MenuStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 98;

  .menuContainer {
    display: flex;
    grid-template-columns: 4fr 1fr 4fr;
    grid-gap: 0;
    position: relative;
    padding: 20px 2.5vw;
    justify-content: space-between;
    @media (min-width: 1170px) {
      padding: 0 7.5vw;
    }

    height: 7.5vw;
    grid-template-areas: 'left center right';
    background: var(--beige);
    border-bottom: 1px solid var(--grey);
    @media (min-width: 768px) {
      background: none;
      border-bottom: none;
    }
  }

  .link {
    text-decoration: none;
  }
  .left {
    grid-area: left;
    flex: 4;
    @media (min-width: 1170px) {
      flex: 2;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-area: left;
    text-transform: uppercase;
    .star {
      max-width: 30px;
      margin-right: 15px;
      width: 20px;
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    .hidesmall {
      display: none;
      @media (min-width: 1170px) {
        display: block;
      }
    }
    img {
      max-width: 130px;

      width: 130px;
    }
  }
  .right {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: flex-end;
    grid-area: right;
    @media (min-width: 1170px) {
      align-items: center;
      justify-content: flex-end;
    }
    .hidesmall {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    .passes {
      margin: 0 30px 0 0;
      text-align: right;
      &.hidesmall {
        display: none;
        @media (min-width: 1170px) {
          display: block;
        }
      }
    }
    .menu-button {
      position: relative;
      color: var(--beige);
      background: var(--olive);
      height: 60px;
      width: 60px;
      @media (min-width: 768px) {
        height: 80px;
        width: 80px;
      }
      border-radius: 100%;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--earth);
      text-transform: uppercase;
      transition: all 0.3s cubic-bezier(0.26, 1.04, 0.54, 1);

      &:before {
        content: '';
        position: absolute;
        border: 1px solid var(--earth);
        height: 80px;
        width: 80px;
        border-radius: 100%;
        left: 50%;
        top: 50%;
        bottom: 0;
        right: 0;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-out;
      }
      &:hover {
        opacity: 1;
        background: var(--rust);
        color: var(--beige);
        &:before {
          height: 100px;
          width: 100px;
          opacity: 1;
        }
      }
    }
  }
`;

/*
 <p className="i">
              LIMITED PASSES AVAILABLE&nbsp;
              <span className="b">
                <a href="">HERE</a>
              </span>
            </p>
*/

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Menu({ open, setOpen }) {
  const className = open ? 'open' : 'closed';
  return (
    <MenuStyles className={className}>
      <div className="menuContainer">
        <div className="left">
          {' '}
          <div className="star">
            <img src={star} alt="" />
          </div>
          <AniLink
            className="link"
            to="/"
            cover
            direction="down"
            bg={transitionImageDiv}
          >
            <p className="b-i">
              Magnetic Fields Nomads _&nbsp;
              <span className=" i">19-21 March 2021</span>
            </p>
          </AniLink>
        </div>
        <div className="center">
          <AniLink
            className="link hidesmall"
            to="/"
            cover
            direction="down"
            bg={transitionImageDiv}
          >
            <img src={logo} alt="" />
          </AniLink>
        </div>
        <div className="right">
          <div className="passes hidesmall">
            <p className="i">
              LIMITED PASSES AVAILABLE{' '}
              <span className="sb-i">
                <a href="https://tickets.magneticfields.in">HERE</a>
              </span>
            </p>
          </div>
          <div
            className="menu-button b"
            open={open}
            onClick={() => setOpen(!open)}
          >
            Menu
          </div>
        </div>
      </div>
    </MenuStyles>
  );
}
Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};
